.results {
  display: grid;
  grid-template-columns: 40rem 1fr;
  width: 100%;
  justify-content: flex-start;

  flex: 0 0 0;
}

.results__destinations {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4.8rem);
}
.results__destinations-header {
  flex: 0 0 5.5rem;
  padding: 0.5rem;
}
.results__destinations-list-container {
  flex: 1 0 0;
  overflow: scroll;
}
.results__no-flights {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.results__destinations-loading {
  flex: 1 0 auto;
}
.results__destinations-list {
  flex: 1 0 auto;
  &--trans-appear,
  &--trans-enter {
    opacity: 0;
  }
  &--trans-appear-active,
  &--trans-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-out;
  }
  &--trans-exit {
    opacity: 1;
  }
  &--trans-exit-active {
    opacity: 0;
    position: absolute;
    transition: opacity 150ms ease-out;
  }
}
.results__map {
  display: flex;
  flex-direction: column;
}
.results__google-maps {
  flex: 1 0 auto;
}
