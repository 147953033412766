@import '../../../assets/styles/variables';

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 3.3rem);
  background-color: map-get($colors, 'white');
  padding-bottom: 3rem;
  &:before {
    content: '';
    position: absolute;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0) 90%
      ),
      url('../../../assets/images/home-bg.jpg') no-repeat center 80%;
    background-size: cover;
    width: 100%;
    height: calc(40vh + 3.3rem);
    z-index: 1;
    margin-top: -3.3rem;
    @include media-breakpoint-up(sm) {
      height: calc(60vh + 3.3rem);
    }
  }
}

.home-page__header-container {
  display: flex;
  width: 100%;
  max-width: calc(100% - 2rem);
  height: calc(40vh - 3.3rem);
  z-index: 2;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @include media-breakpoint-up(xs) {
    width: map-get($container-max-widths, 'xs');
  }
  @include media-breakpoint-up(sm) {
    height: calc(60vh - 3.3rem);
    width: map-get($container-max-widths, 'sm');
  }
  @include media-breakpoint-up(md) {
    width: map-get($container-max-widths, 'md');
  }
  @include media-breakpoint-up(lg) {
    width: 45rem;
  }
}
.home-page__header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
}

.home-page__main-title {
  font-size: 2rem;
  font-weight: 600;
  @include media-breakpoint-up(sm) {
    font-size: 3.5rem;
  }
}

.home-page__sub-title {
  font-size: 1rem;
  font-weight: 300;
  @include media-breakpoint-up(sm) {
    font-size: 1.875rem;
  }
}

.home-page__search {
  display: flex;
  width: 100%;
  z-index: 2;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    width: map-get($container-max-widths, 'xs');
  }
  @include media-breakpoint-up(sm) {
    width: map-get($container-max-widths, 'sm');
  }
  @include media-breakpoint-up(md) {
    width: map-get($container-max-widths, 'md');
  }
  @include media-breakpoint-up(lg) {
    width: 45rem;
  }
}

.home-page__search-bar {
  background-color: map-get($colors, 'white');
  padding: 1rem 2rem;
  width: 100%;
}

.home-page__explanation-search-image {
  width: 100%;
}
.home-page__explanation-search {
  margin-top: 3rem;
  display: grid;
  width: 100%;
  z-index: 2;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(xs) {
    width: map-get($container-max-widths, 'xs');
  }
  @include media-breakpoint-up(sm) {
    width: map-get($container-max-widths, 'sm');
  }
  @include media-breakpoint-up(md) {
    width: map-get($container-max-widths, 'md');
    grid-template-columns: auto 1fr;
  }
  @include media-breakpoint-up(lg) {
    width: 45rem;
  }
}

.home-page__explanation-points {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-content: space-around;
  row-gap: 1.5rem;
}

.home-page__explanation-title {
  font-size: 2.2rem;
  font-weight: bold;
  grid-column: span 3;
}

.home-page__explanation-point {
  grid-column: span 2;
}

.home-page__explanation-icon {
  align-self: center;
  justify-self: center;
  height: 2rem;
}

.home-page__explanation-results-image {
  width: 100%;
}
.home-page__explanation-results {
  margin-top: 3rem;
  display: grid;
  width: 100%;
  z-index: 2;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 1rem;

  @include media-breakpoint-up(xs) {
    width: map-get($container-max-widths, 'xs');
  }
  @include media-breakpoint-up(sm) {
    width: map-get($container-max-widths, 'sm');
  }
  @include media-breakpoint-up(md) {
    width: map-get($container-max-widths, 'md');
  }
  @include media-breakpoint-up(lg) {
    width: 45rem;
  }
}

.home-page__explanation-results-left {
  padding: 22% 0 8% 0;
  display: grid;
  grid-template-rows: 15% 42.5% 42.5%;
  justify-items: end;

  > :nth-child(1) {
    display: flex;
    align-items: flex-end;
  }
  > :nth-child(2) {
    display: flex;
    align-items: center;
    color: map-get($colors, 'primary');
  }
}

.home-page__explanation-results-right {
  padding: 22% 0 8% 0;
  display: grid;
  grid-template-rows: 15% 42.5% 42.5%;

  > :nth-child(1) {
    display: flex;
    align-items: flex-start;
  }
  > :nth-child(2) {
    display: flex;
    align-items: flex-end;
  }
  > :nth-child(3) {
    display: flex;
    align-items: center;
    color: map-get($colors, 'secondary');
  }
}
