@import '../../assets/styles/variables';
.search-bar {
  display: flex;
  @include media-breakpoint-down(xxs) {
    width: calc(100% - 2rem);
  }
}

.search-bar__form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  align-items: flex-start;
  @include media-breakpoint-up(xs) {
    grid-template-columns: 1fr 1fr;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: [origin1] 1fr [origin2] 1fr [date-range] 1fr [submit] 3rem;
  }
}

.search-bar__input-column {
  display: flex;
  flex-direction: column;
}
.search-bar__input-label {
  margin-left: 0.9rem;
}

.search-bar__submit {
  width: 2.4rem;
  height: 2.4rem;

  @include media-breakpoint-down(xxs) {
    justify-self: end;
  }
  @include media-breakpoint-up(xxs) {
    margin-top: 1.2rem;
  }
}
