@import '../../assets/styles/variables';

.button {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: normal;
  text-decoration: none;
  border: 0;
  line-height: 1;

  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &--small {
    font-size: 0.8rem;
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &--center-children {
    justify-content: center;
  }
}

.button__icon {
  &--right {
    margin-left: 0.6rem;
  }

  &--left {
    margin-right: 0.6rem;
  }
}
