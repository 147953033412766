@import '../../assets/styles/variables';

.input {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;

  &--full-width {
    display: flex;
    flex: 1 0 0;
  }
}

.input__field {
  font-family: $noto;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  color: map-get($colors, 'black');
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: normal;
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  border: 1px solid rgba(map-get($colors, 'primary'), 0.5);
  outline: 0px solid transparent;
  width: 100%;
  border-radius: 1px;
  line-height: 1;
  height: 2.4rem;

  &:focus {
    border-color: map-get($colors, 'primary');
  }

  .input--disabled & {
    cursor: not-allowed;
    pointer-events: none;
    color: map-get($colors, 'black');
    background-color: rgba(map-get($colors, 'black'), 0.5);
  }
  .input--error & {
    background-color: rgba(map-get($colors, 'error'), 0.1);
    border-color: map-get($colors, 'error');
  }
}

.input__field:-webkit-autofill,
.input__field:-webkit-autofill:hover,
.input__field:-webkit-autofill:focus {
  font: inherit;
  font-family: $noto;
  font-size: 0.9rem;
  font-weight: 400;
  -webkit-text-fill-color: map-get($colors, 'black');
}

.input__placeholder {
  position: absolute;
  pointer-events: none;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: map-get($colors, 'black');
  transition: opacity 150ms ease-out;

  .input__field:-webkit-autofill + &,
  .input--focused & {
    opacity: 0;
  }

  .input--disabled & {
    color: map-get($colors, 'black');
  }

  .input--error & {
    color: map-get($colors, 'error');
  }
  .input--error.input--disabled & {
    color: map-get($colors, 'error');
  }

  .input__field:-webkit-autofill + & {
    color: map-get($colors, 'primary');
  }
}

.input__helper-text {
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: map-get($colors, 'primary');
  margin-left: 0.8rem;
}
