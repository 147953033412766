@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700|Source+Sans+Pro:300,400,600&display=swap);
.ac-select-box-native {
  display: inline-flex;
  flex-direction: column;
  position: relative; }

.ac-select-box-native__value {
  padding: 1rem 1.4rem;
  border-radius: 0.6rem;
  line-height: 1;
  font-size: 1.8rem;
  background-color: #fff;
  color: #000;
  width: 100%; }
  .ac-select-box-native--transparent .ac-select-box-native__value {
    background-color: transparent;
    color: #fff; }
  .ac-select-box-native--has-selection .ac-select-box-native__value {
    color: #00527c; }
  .ac-select-box-native--focused .ac-select-box-native__value {
    box-shadow: rgba(5, 156, 226, 0.5) 0px 0px 2px 2px; }
  .ac-select-box-native--has-selection.ac-select-box-native--transparent .ac-select-box-native__value {
    color: #fff; }
  .ac-select-box-native--disabled.ac-select-box-native--transparent .ac-select-box-native__value {
    color: rgba(255, 255, 255, 0.5); }
  .ac-select-box-native__value:focus {
    outline: 0;
    box-shadow: rgba(5, 156, 226, 0.5) 0px 0px 2px 2px; }
    .ac-select-box-native--transparent .ac-select-box-native__value:focus {
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.3); }

.ac-select-box-native__select {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.ac-select-box-non-native {
  display: inline-flex;
  flex-direction: column; }

.ac-select-box-non-native__button {
  position: relative;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0.5rem 0.8rem;
  line-height: 1;
  font-size: 1rem;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  font-family: inherit; }
  .ac-select-box-non-native--transparent .ac-select-box-non-native__button {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8); }
  .ac-select-box-non-native--has-selection .ac-select-box-non-native__button {
    color: #000; }
  .ac-select-box-non-native--disabled .ac-select-box-non-native__button {
    color: rgba(0, 0, 0, 0.5); }
  .ac-select-box-non-native--has-selection.ac-select-box-non-native--transparent
.ac-select-box-non-native__button {
    color: #fff; }
  .ac-select-box-non-native--disabled.ac-select-box-non-native--transparent .ac-select-box-non-native__button {
    color: rgba(255, 255, 255, 0.5); }
  .ac-select-box-non-native__button:focus {
    outline: 0;
    box-shadow: rgba(5, 156, 226, 0.5) 0px 0px 2px 2px; }
    .ac-select-box-non-native--transparent .ac-select-box-non-native__button:focus {
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.3); }

.ac-select-box-non-native__options-container:focus {
  outline: 0; }

.ac-select-box-non-native__options {
  margin: 0;
  margin-top: -1.5rem;
  overflow-y: scroll;
  max-height: 21rem;
  position: absolute;
  z-index: 100000;
  list-style-type: none;
  padding-left: 0;
  max-width: 20rem;
  background-color: #fff; }
  .ac-select-box-non-native--open-top .ac-select-box-non-native__options {
    margin-top: 0;
    margin-bottom: -1.5rem;
    bottom: 100%; }
  .ac-select-box-non-native__options--transition-enter {
    opacity: 0; }
    .ac-select-box-non-native__options--transition-enter.ac-select-box-non-native__options--transition-enter-active {
      transition: opacity 150ms ease-in-out;
      opacity: 1; }
  .ac-select-box-non-native__options--transition-exit {
    opacity: 1; }
    .ac-select-box-non-native__options--transition-exit.ac-select-box-non-native__options--transition-exit-active {
      opacity: 0;
      transition: opacity 150ms ease-in-out; }

.ac-select-box-non-native__option {
  padding: 1rem;
  cursor: pointer;
  white-space: nowrap; }
  .ac-select-box-non-native__option--hover, .ac-select-box-non-native__option--selected {
    background-color: rgba(2, 130, 201, 0.3); }

.header {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  justify-content: space-between;
  padding: 1rem 1rem 0.2rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  z-index: 1000;
  height: 3.3rem;
  align-items: center;
  align-self: stretch;
  width: 100%; }
  .header--home-route {
    position: absolute;
    padding: 1rem 0 0.2rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    align-self: center; }
    @media (min-width: 440px) {
      .header--home-route {
        width: 420px; } }
    @media (min-width: 576px) {
      .header--home-route {
        width: 540px; } }
    @media (min-width: 768px) {
      .header--home-route {
        width: 720px; } }
    @media (min-width: 992px) {
      .header--home-route {
        width: 45rem; } }

.header__logo {
  cursor: pointer;
  background-image: url(../../static/media/logofotblack.ae9cd0c2.png);
  background-size: contain;
  width: 4.375rem;
  height: 2rem; }
  .header--home-route .header__logo {
    background-image: url(../../static/media/logofotwhite.76aaa8bd.png); }

.header__navigation {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  align-items: baseline; }

.header__nav-currency {
  margin-left: -0.8rem; }

.header__nav-link {
  position: relative;
  text-decoration: none;
  color: #000; }
  .header--home-route .header__nav-link {
    color: #fff; }

.about {
  margin-top: 3rem; }

.typography {
  margin: 0;
  line-height: normal; }
  .typography > strong {
    font-weight: 600; }
  .typography--h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff; }
  .typography--h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #fff; }
  .typography--h3 {
    font-size: 0.9rem;
    font-weight: 400;
    color: #fff; }
  .typography--h4 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #fff; }
  .typography--h5 {
    font-size: 0.6rem;
    font-weight: 600;
    color: #000; }
  .typography--body-large {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000; }
  .typography--body {
    font-size: 0.8rem;
    font-weight: 400;
    color: #000; }
  .typography--body-small {
    font-size: 0.7rem;
    font-weight: 400;
    color: #000; }
  .typography--body-small-light {
    font-size: 0.7rem;
    font-weight: 300;
    color: #000; }
  .typography--variant-primary {
    color: #fff; }
  .typography--variant-secondary {
    color: #000; }
  .typography--variant-inherit {
    color: inherit; }
  .typography--line-height-lg {
    line-height: 1.5; }
  .typography--line-height-sm {
    line-height: 1.25; }
  .typography--line-height-xs {
    line-height: 1; }
  .typography--margin-bottom-lg {
    margin-bottom: 1rem; }
  .typography--margin-bottom-sm {
    margin-bottom: 0.5rem; }
  .typography--margin-bottom-xs {
    margin-bottom: 0.25rem; }
  .typography--margin-top-lg {
    margin-top: 1rem; }
  .typography--margin-top-sm {
    margin-top: 0.5rem; }
  .typography--margin-top-xs {
    margin-top: 0.25rem; }

.responsive-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.responsive-container__content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 3rem); }
  @media (min-width: 440px) {
    .responsive-container__content {
      width: 420px; } }
  @media (min-width: 576px) {
    .responsive-container__content {
      width: 540px; } }
  @media (min-width: 768px) {
    .responsive-container__content {
      width: 720px; } }
  @media (min-width: 992px) {
    .responsive-container__content {
      width: 45rem; } }

.list {
  margin: 1rem 0;
  padding-left: 0.9rem; }
  .list--no-margin-top {
    margin-top: 0; }
  .list--no-margin-bottom {
    margin-bottom: 0; }
  .list--no-margin {
    margin-top: 0;
    margin-bottom: 0; }
  .list--list-type-check {
    padding-left: 1.1rem; }

.list-item {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  list-style-type: none;
  position: relative;
  line-height: 1.44; }
  .list-item:before {
    content: '\2022';
    position: absolute;
    left: -0.9rem;
    font-size: 1.32rem;
    line-height: 0.7; }
  .list-item + .list-item {
    margin-top: 0.5rem; }
    .list-item + .list-item.list-item--large-spacing {
      margin-top: 0.75rem; }
    .list-item + .list-item.list-item--small-spacing {
      margin-top: 0; }
  .list-item--variant-primary {
    color: #000; }
  .list-item--variant-secondary {
    color: #059ce2; }
  .list-item--list-type-check:before {
    content: '';
    left: -1.1rem;
    width: 0.6rem;
    height: 1.1rem;
    background-size: contain;
    background-position: center;
    background-image: url(../../static/media/check.927a32eb.svg);
    background-repeat: no-repeat; }
  .list-item--line-height-small {
    line-height: 1; }
  .list-item--line-height-large {
    line-height: 1.88; }
  .list-item strong {
    font-weight: 600; }

.icon--margin-left {
  margin-left: 0.5rem; }
  .icon--small-margin .icon--margin-left {
    margin-left: 0.25rem; }

.icon--margin-right {
  margin-right: 0.5rem; }
  .icon--small-margin .icon--margin-right {
    margin-right: 0.25rem; }

.icon--variant-primary {
  fill: #000; }

.icon--variant-secondary {
  fill: #059ce2; }

.icon--variant-error {
  fill: #f00; }

.error-message {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: #f00; }
  .error-message:not(.error-message--no-margin) {
    margin-left: 0.2rem; }

.error-message__icon {
  margin-right: 0.2rem;
  margin-top: 0.2rem; }

.input {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column; }
  .input--full-width {
    display: flex;
    flex: 1 0; }

.input__field {
  font-family: "Noto Sans", sans-serif;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  color: #000;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: normal;
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  border: 1px solid rgba(5, 156, 226, 0.5);
  outline: 0px solid transparent;
  width: 100%;
  border-radius: 1px;
  line-height: 1;
  height: 2.4rem; }
  .input__field:focus {
    border-color: #059ce2; }
  .input--disabled .input__field {
    cursor: not-allowed;
    pointer-events: none;
    color: #000;
    background-color: rgba(0, 0, 0, 0.5); }
  .input--error .input__field {
    background-color: rgba(255, 0, 0, 0.1);
    border-color: #f00; }

.input__field:-webkit-autofill,
.input__field:-webkit-autofill:hover,
.input__field:-webkit-autofill:focus {
  font: inherit;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  -webkit-text-fill-color: #000; }

.input__placeholder {
  position: absolute;
  pointer-events: none;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
  transition: opacity 150ms ease-out; }
  .input__field:-webkit-autofill + .input__placeholder,
  .input--focused .input__placeholder {
    opacity: 0; }
  .input--disabled .input__placeholder {
    color: #000; }
  .input--error .input__placeholder {
    color: #f00; }
  .input--error.input--disabled .input__placeholder {
    color: #f00; }
  .input__field:-webkit-autofill + .input__placeholder {
    color: #059ce2; }

.input__helper-text {
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: #059ce2;
  margin-left: 0.8rem; }

.auto-complete {
  display: inline-flex;
  position: relative; }

.auto-complete__input {
  flex: 1 1; }

.auto-complete__menu {
  z-index: 1;
  padding: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  background-color: #fff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1); }

.auto-complete__menu-item {
  cursor: pointer; }
  .auto-complete__menu-item + .auto-complete__menu-item {
    margin-top: 0.5rem; }

.button {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: normal;
  text-decoration: none;
  border: 0;
  line-height: 1;
  cursor: pointer; }
  .button:focus {
    outline: 0; }
  .button--small {
    font-size: 0.8rem; }
  .button--disabled {
    cursor: not-allowed;
    pointer-events: none; }
  .button--center-children {
    justify-content: center; }

.button__icon--right {
  margin-left: 0.6rem; }

.button__icon--left {
  margin-right: 0.6rem; }

.button--variant-icon {
  padding: 0.6rem;
  background-color: rgba(5, 156, 226, 0.05);
  border-radius: 100%;
  transition: background-color 250ms ease-out;
  align-items: center;
  justify-content: center; }
  .button--variant-icon:hover {
    text-decoration: underline; }
  .button--variant-icon:focus {
    text-decoration: underline; }
  .button--variant-icon.button--small {
    padding: 0.3rem 0.3rem; }
  .button--variant-icon.button--small-padding {
    padding: 0.2rem 0.2rem; }
  .button--variant-icon.button--no-padding {
    padding: 0;
    background-color: transparent; }
  .button--variant-icon:hover:not(.button--no-padding) {
    background-color: rgba(5, 156, 226, 0.2); }

.button__icon-child {
  width: 0.9rem;
  height: 0.9rem;
  fill: #059ce2;
  transition: fill 250ms ease-out; }
  .button.button--no-padding:hover .button__icon-child {
    fill: #000; }

.date-range {
  display: inline-flex;
  flex-direction: column;
  min-width: 11rem; }
  .date-range--full-width {
    display: inline-flex;
    flex: 1 0; }

.date-range__menu {
  z-index: 100000; }

.date-range__button {
  font-family: "Noto Sans", sans-serif;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: normal;
  color: #000;
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  border: 1px solid rgba(5, 156, 226, 0.5);
  outline: 0px solid transparent;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  line-height: 1;
  height: 2.4rem; }
  .date-range--disabled .date-range__button {
    cursor: not-allowed;
    pointer-events: none;
    color: #000;
    background-color: rgba(0, 0, 0, 0.5); }
  .date-range--error .date-range__button {
    background-color: rgba(255, 0, 0, 0.5); }
    .date-range--error .date-range__button:focus {
      outline: 2px solid #f00; }

.date-range__button:-webkit-autofill,
.date-range__button:-webkit-autofill:hover,
.date-range__button:-webkit-autofill:focus {
  font: inherit;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  -webkit-text-fill-color: #000; }

.date-range__placeholder {
  position: absolute;
  pointer-events: none;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #000;
  transition: opacity 150ms ease-out; }
  .date-range--focused .date-range__placeholder {
    opacity: 0; }
  .date-range--disabled .date-range__placeholder {
    color: #000; }
  .date-range--error .date-range__placeholder {
    color: #f00; }
  .date-range--error.date-range--disabled .date-range__placeholder {
    color: #f00; }

.date-range__helper-text {
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: #059ce2;
  margin-left: 0.8rem; }

.search-bar {
  display: flex; }
  @media (max-width: 439.98px) {
    .search-bar {
      width: calc(100% - 2rem); } }

.search-bar__form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  align-items: flex-start; }
  @media (min-width: 440px) {
    .search-bar__form {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 768px) {
    .search-bar__form {
      grid-template-columns: [origin1] 1fr [origin2] 1fr [date-range] 1fr [submit] 3rem; } }

.search-bar__input-column {
  display: flex;
  flex-direction: column; }

.search-bar__input-label {
  margin-left: 0.9rem; }

.search-bar__submit {
  width: 2.4rem;
  height: 2.4rem;
  margin-top: 1.2rem; }
  @media (max-width: 439.98px) {
    .search-bar__submit {
      justify-self: end; } }

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 3.3rem);
  background-color: #fff;
  padding-bottom: 3rem; }
  .home-page:before {
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 90%), url(../../static/media/home-bg.01c986c1.jpg) no-repeat center 80%;
    background-size: cover;
    width: 100%;
    height: calc(40vh + 3.3rem);
    z-index: 1;
    margin-top: -3.3rem; }
    @media (min-width: 576px) {
      .home-page:before {
        height: calc(60vh + 3.3rem); } }

.home-page__header-container {
  display: flex;
  width: 100%;
  max-width: calc(100% - 2rem);
  height: calc(40vh - 3.3rem);
  z-index: 2;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; }
  @media (min-width: 440px) {
    .home-page__header-container {
      width: 420px; } }
  @media (min-width: 576px) {
    .home-page__header-container {
      height: calc(60vh - 3.3rem);
      width: 540px; } }
  @media (min-width: 768px) {
    .home-page__header-container {
      width: 720px; } }
  @media (min-width: 992px) {
    .home-page__header-container {
      width: 45rem; } }

.home-page__header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0; }

.home-page__main-title {
  font-size: 2rem;
  font-weight: 600; }
  @media (min-width: 576px) {
    .home-page__main-title {
      font-size: 3.5rem; } }

.home-page__sub-title {
  font-size: 1rem;
  font-weight: 300; }
  @media (min-width: 576px) {
    .home-page__sub-title {
      font-size: 1.875rem; } }

.home-page__search {
  display: flex;
  width: 100%;
  z-index: 2;
  justify-content: center; }
  @media (min-width: 440px) {
    .home-page__search {
      width: 420px; } }
  @media (min-width: 576px) {
    .home-page__search {
      width: 540px; } }
  @media (min-width: 768px) {
    .home-page__search {
      width: 720px; } }
  @media (min-width: 992px) {
    .home-page__search {
      width: 45rem; } }

.home-page__search-bar {
  background-color: #fff;
  padding: 1rem 2rem;
  width: 100%; }

.home-page__explanation-search-image {
  width: 100%; }

.home-page__explanation-search {
  margin-top: 3rem;
  display: grid;
  width: 100%;
  z-index: 2;
  grid-column-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: 1fr; }
  @media (min-width: 440px) {
    .home-page__explanation-search {
      width: 420px; } }
  @media (min-width: 576px) {
    .home-page__explanation-search {
      width: 540px; } }
  @media (min-width: 768px) {
    .home-page__explanation-search {
      width: 720px;
      grid-template-columns: auto 1fr; } }
  @media (min-width: 992px) {
    .home-page__explanation-search {
      width: 45rem; } }

.home-page__explanation-points {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-content: space-around;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem; }

.home-page__explanation-title {
  font-size: 2.2rem;
  font-weight: bold;
  grid-column: span 3; }

.home-page__explanation-point {
  grid-column: span 2; }

.home-page__explanation-icon {
  align-self: center;
  justify-self: center;
  height: 2rem; }

.home-page__explanation-results-image {
  width: 100%; }

.home-page__explanation-results {
  margin-top: 3rem;
  display: grid;
  width: 100%;
  z-index: 2;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem; }
  @media (min-width: 440px) {
    .home-page__explanation-results {
      width: 420px; } }
  @media (min-width: 576px) {
    .home-page__explanation-results {
      width: 540px; } }
  @media (min-width: 768px) {
    .home-page__explanation-results {
      width: 720px; } }
  @media (min-width: 992px) {
    .home-page__explanation-results {
      width: 45rem; } }

.home-page__explanation-results-left {
  padding: 22% 0 8% 0;
  display: grid;
  grid-template-rows: 15% 42.5% 42.5%;
  justify-items: end; }
  .home-page__explanation-results-left > :nth-child(1) {
    display: flex;
    align-items: flex-end; }
  .home-page__explanation-results-left > :nth-child(2) {
    display: flex;
    align-items: center;
    color: #059ce2; }

.home-page__explanation-results-right {
  padding: 22% 0 8% 0;
  display: grid;
  grid-template-rows: 15% 42.5% 42.5%; }
  .home-page__explanation-results-right > :nth-child(1) {
    display: flex;
    align-items: flex-start; }
  .home-page__explanation-results-right > :nth-child(2) {
    display: flex;
    align-items: flex-end; }
  .home-page__explanation-results-right > :nth-child(3) {
    display: flex;
    align-items: center;
    color: #f19000; }

.lottie-animation {
  position: relative;
  display: block; }

.page {
  display: flex;
  flex: 1 0;
  transition: background-color 200ms ease-out;
  position: relative;
  flex-direction: column;
  flex: 1 0 100%; }

.collapsible {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  transition: border-color 250ms ease-out, box-shadow 250ms ease-out; }
  .collapsible + .collapsible {
    margin-top: 0.5rem; }
  .collapsible--open {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); }
  .collapsible--error {
    border: 1px solid rgba(255, 0, 0, 0.5); }
    .collapsible--error.collapsible--open {
      border: 1px solid #f00; }

.collapsible__header {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  justify-content: center;
  cursor: pointer; }

.collapsible__container--transition-enter {
  height: 0; }
  .collapsible__container--transition-enter.collapsible__container--transition-enter-active {
    position: relative;
    height: 100px;
    transition: height 250ms ease-in-out;
    overflow: hidden; }

.collapsible__container--transition-exit {
  height: 100px; }
  .collapsible__container--transition-exit.collapsible__container--transition-exit-active {
    position: relative;
    height: 0;
    transition: height 250ms ease-in-out;
    overflow: hidden; }

.collapsible__container-content {
  padding: 0 1rem 1rem;
  overflow: hidden; }

.collapsible__label {
  color: #059ce2;
  font-size: 0.9rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 400;
  flex: 1 1 auto;
  padding-right: 1rem; }
  .collapsible--error .collapsible__label {
    color: #f00; }

.collapsible__label--icon-left .collapsible__label-icon {
  margin-right: 0.5rem; }

.collapsible__label--icon-right .collapsible__label-icon {
  margin-left: 0.5rem; }

.collapsible__header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.2rem;
  height: 1.2rem; }

.collapsible__expand-icon {
  fill: #059ce2;
  transition: transform 250ms ease-out; }
  .collapsible--open .collapsible__expand-icon {
    transform: rotate(-180deg); }

.collapsible-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  flex: 1 0; }

.collapsible-header__city {
  font-size: 2rem;
  line-height: 1; }

.collapsible-header__city-label {
  font-size: 0.9rem;
  line-height: 1; }

.collapsible-header__price {
  font-size: 2rem;
  line-height: 1;
  align-self: flex-end; }

.collapsible-header__price-label {
  font-size: 0.9rem;
  line-height: 1;
  align-self: flex-end; }

.collapsible-header__column {
  display: flex;
  flex-direction: column; }

.collapsible-header__row {
  display: flex;
  align-items: baseline; }

.collapsible-header__aiport {
  font-size: 0.75rem;
  line-height: 1;
  padding-left: 0.5rem; }

.button--variant-primary {
  color: #fff;
  background-color: #059ce2;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.24);
  padding: 0.65rem 1.7rem;
  transition: background-color 250ms ease-out; }
  .button--variant-primary:hover {
    background-color: #20b5fa; }
  .button--variant-primary:focus {
    background-color: #047ab0; }
  .button--variant-primary.button--disabled {
    color: rgba(255, 255, 255, 0.2);
    background-color: rgba(5, 156, 226, 0.2); }

.button--variant-primary .button__icon {
  fill: #fff; }

.button--variant-primary.button--disabled .button__icon {
  fill: rgba(255, 255, 255, 0.2); }

.detail-route {
  display: flex;
  align-items: flex-start;
  flex-direction: column; }

.detail-route__details {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em; }

.detail-route__flight-codes {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.00735em; }

.detail-route__icon {
  fill: #059ce2; }

.detail-route__airports {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  border-bottom: 0.1rem solid rgba(45, 49, 66, 0.3);
  padding-bottom: 0.5rem;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em; }

.detail-route__flight-carrier {
  display: grid;
  grid-template-columns: 1rem 1fr auto;
  grid-column-gap: 0.2rem;
  width: 100%;
  justify-content: flex-start;
  padding-top: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em; }

.detail-route__flight-carrier-logo {
  width: 1rem; }

.detail-route__flight-times {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1rem;
  width: 100%;
  justify-content: space-between;
  padding-top: 0.5rem;
  align-items: end;
  height: 2.7rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em; }

.detail-route__flight-duration {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  align-self: start;
  letter-spacing: 0.03333em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #737373;
  flex: 1 1; }

/* root: {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
},
icon: { flex: `0 0 ${theme.spacing(2)}px`, fill: teal.A700 },
airports: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  borderBottom: '1.5px solid rgba(45,49,66,0.3)',
  paddingBottom: theme.spacing(1)
},
airport: { flex: '1 0 0%', display: 'flex', flexDirection: 'column' },
airportNameLeft: { paddingRight: theme.spacing(2) },
airportNameRight: { paddingLeft: theme.spacing(2) },
airportRight: {
  alignItems: 'flex-end'
},
flightCarrier: {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(1),
  alignItems: 'center'
},
flightCarrierLogo: {
  flex: `0 0 ${theme.spacing(2)}px`,
  height: theme.spacing(2)
},
flightCarrierName: {
  flex: `1 0 0%`,
  paddingLeft: theme.spacing(1)
},
flightStopovers: {},
flightTimes: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  alignItems: 'flex-start'
},
flightCodes: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
},
details: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between'
},
time: {},
duration: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
  borderBottom: '1px solid #737373',
  flex: 1,
  margin: `0 ${theme.spacing(2)}px`
} */

.flight-details {
  display: flex;
  flex-direction: column; }

.flight-details__routes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  justify-content: space-between; }

.flight-details__action {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
  width: 100%; }

.flight-details__price {
  font-size: 2.125rem;
  font-weight: 400; }

.flight-details__button {
  margin-left: 1rem; }

.flight-details__button-icon {
  margin-left: 1rem; }

.flight-list-item {
  width: calc(100% - 4rem);
  transition: width 250ms ease-out; }
  .flight-list-item--open {
    width: calc(100% - 2rem); }

.flight-list-item__summary-left {
  flex: 1 1 auto;
  padding-right: 0.5rem; }

.flight-list-item__summary-right {
  flex: 1 1 auto;
  padding-left: 0.5rem;
  align-items: flex-end;
  text-align: right; }

.flight-list-item__head-sub {
  font-size: 0.75rem;
  line-height: 1; }

.flight-list-item__head-row {
  display: flex;
  align-items: baseline; }

.flight-list-item__head-title {
  font-size: 2rem;
  line-height: 1; }

.flight-list-item__head-nights {
  font-size: 0.75rem;
  line-height: 1;
  padding-left: 0.5rem; }

.flight-list-item__panel-details {
  display: flex;
  flex-direction: column; }

.flight-list-item__detail + .flight-list-item__detail {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #000; }

.flight-list {
  padding: 0.25rem;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center; }

.results {
  display: grid;
  grid-template-columns: 40rem 1fr;
  width: 100%;
  justify-content: flex-start;
  flex: 0 0; }

.results__destinations {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4.8rem); }

.results__destinations-header {
  flex: 0 0 5.5rem;
  padding: 0.5rem; }

.results__destinations-list-container {
  flex: 1 0;
  overflow: scroll; }

.results__no-flights {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column; }

.results__destinations-loading {
  flex: 1 0 auto; }

.results__destinations-list {
  flex: 1 0 auto; }
  .results__destinations-list--trans-appear, .results__destinations-list--trans-enter {
    opacity: 0; }
  .results__destinations-list--trans-appear-active, .results__destinations-list--trans-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-out; }
  .results__destinations-list--trans-exit {
    opacity: 1; }
  .results__destinations-list--trans-exit-active {
    opacity: 0;
    position: absolute;
    transition: opacity 150ms ease-out; }

.results__map {
  display: flex;
  flex-direction: column; }

.results__google-maps {
  flex: 1 0 auto; }

.pages {
  display: flex;
  flex: 1 0;
  transition: background-color 200ms ease-out;
  align-items: center;
  position: relative;
  flex-direction: column; }

.pages__header {
  flex: 0 0 3rem;
  display: flex;
  align-items: center; }

.pages__container {
  position: relative;
  display: flex;
  flex: 1 0 100%;
  overflow: hidden;
  width: 100%; }

.pages__page {
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  padding-top: 1.5rem; }
  .pages__page--fade-trans-enter {
    opacity: 0; }
  .pages__page--fade-trans-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-out, transform 500ms ease-out;
    height: 100%; }
  .pages__page--fade-trans-exit {
    opacity: 1; }
  .pages__page--fade-trans-exit-active {
    opacity: 0;
    height: 100%; }

.faq {
  margin-top: 3rem; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.15;
  font-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  /* stylelint-disable-line at-rule-no-vendor-prefix */
  width: device-width; }

body {
  display: flex;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  text-decoration: none;
  color: #000;
  flex-direction: column; }

.app {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  transition: background-color 300ms ease-out;
  background-color: #fff;
  flex: 1 0 100%;
  display: flex; }

