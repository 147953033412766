@import '../../assets/styles/variables';

.header {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
  justify-content: space-between;
  padding: 1rem 1rem 0.2rem 1rem;
  border-bottom: 1px solid rgba(map-get($colors, 'white'), 0);
  z-index: 1000;
  height: 3.3rem;
  align-items: center;
  align-self: stretch;
  width: 100%;
  &--home-route {
    position: absolute;
    padding: 1rem 0 0.2rem 0;
    border-bottom: 1px solid rgba(map-get($colors, 'white'), 0.5);
    align-self: center;
    @include media-breakpoint-up(xs) {
      width: map-get($container-max-widths, 'xs');
    }
    @include media-breakpoint-up(sm) {
      width: map-get($container-max-widths, 'sm');
    }
    @include media-breakpoint-up(md) {
      width: map-get($container-max-widths, 'md');
    }
    @include media-breakpoint-up(lg) {
      width: 45rem;
    }
  }
}

.header__logo {
  cursor: pointer;
  background-image: url('../../assets/images/logofotblack.png');
  .header--home-route & {
    background-image: url('../../assets/images/logofotwhite.png');
  }
  background-size: contain;
  width: 4.375rem;
  height: 2rem;
}

.header__navigation {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  column-gap: 1.5rem;
  align-items: baseline;
}
.header__nav-currency {
  margin-left: -0.8rem;
}
.header__nav-link {
  position: relative;
  text-decoration: none;
  color: map-get($colors, 'black');
  .header--home-route & {
    color: map-get($colors, 'white');
  }
}
