.auto-complete {
  display: inline-flex;
  position: relative;
}

.auto-complete__input {
  flex: 1;
}

.auto-complete__menu {
  z-index: 1;
  padding: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  background-color: #fff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
}

.auto-complete__menu-item {
  cursor: pointer;
  & + & {
    margin-top: 0.5rem;
  }
}
