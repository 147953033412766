@import '../../assets/styles/variables';
.ac-select-box-non-native {
  display: inline-flex;
  flex-direction: column;
}

.ac-select-box-non-native__button {
  position: relative;
  appearance: none;
  border: 0;
  padding: 0.5rem 0.8rem;
  line-height: 1;
  font-size: 1rem;
  background-color: map-get($colors, 'white');
  color: rgba(map-get($colors, 'black'), 0.8);
  .ac-select-box-non-native--transparent & {
    background-color: transparent;
    color: rgba(map-get($colors, 'white'), 0.8);
  }
  width: 100%;
  font-family: inherit;

  .ac-select-box-non-native--has-selection & {
    color: map-get($colors, 'black');
  }
  .ac-select-box-non-native--disabled & {
    color: rgba(map-get($colors, 'black'), 0.5);
  }
  .ac-select-box-non-native--has-selection.ac-select-box-non-native--transparent
    & {
    color: map-get($colors, 'white');
  }
  .ac-select-box-non-native--disabled.ac-select-box-non-native--transparent & {
    color: rgba(map-get($colors, 'white'), 0.5);
  }
  &:focus {
    outline: 0;
    box-shadow: rgba(map-get($colors, 'primary'), 0.5) 0px 0px 2px 2px;
    .ac-select-box-non-native--transparent & {
      box-shadow: none;
      background-color: rgba(map-get($colors, 'white'), 0.3);
    }
  }
}

.ac-select-box-non-native__options-container {
  &:focus {
    outline: 0;
  }
}

.ac-select-box-non-native__options {
  margin: 0;
  margin-top: -1.5rem;
  overflow-y: scroll;
  max-height: 21rem;
  position: absolute;
  z-index: 100000;
  list-style-type: none;
  padding-left: 0;
  max-width: 20rem;
  background-color: map-get($colors, 'white');

  .ac-select-box-non-native--open-top & {
    margin-top: 0;
    margin-bottom: -1.5rem;
    bottom: 100%;
  }

  &--transition {
    &-enter {
      opacity: 0;
      &.ac-select-box-non-native__options--transition-enter-active {
        transition: opacity 150ms ease-in-out;
        opacity: 1;
      }
    }
    &-exit {
      opacity: 1;
      &.ac-select-box-non-native__options--transition-exit-active {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
      }
    }
  }
}

.ac-select-box-non-native__option {
  padding: 1rem;
  cursor: pointer;
  white-space: nowrap;

  &--hover,
  &--selected {
    background-color: rgba(map-get($colors, 'medium'), 0.3);
  }
}
