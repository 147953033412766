@import '../../assets/styles/variables';

.list {
  margin: 1rem 0;
  padding-left: 0.9rem;

  &--no-margin-top {
    margin-top: 0;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &--no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--list-type-check {
    padding-left: 1.1rem;
  }
}
