@import '../../assets/styles/variables';

.list-item {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  list-style-type: none;
  position: relative;
  line-height: 1.44;

  &:before {
    content: '\2022';
    position: absolute;
    left: -0.9rem;
    font-size: 1.32rem;
    line-height: 0.7;
  }

  & + & {
    margin-top: 0.5rem;

    &.list-item--large-spacing {
      margin-top: 0.75rem;
    }

    &.list-item--small-spacing {
      margin-top: 0;
    }
  }

  &--variant-primary {
    color: map-get($colors, 'black');
  }

  &--variant-secondary {
    color: map-get($colors, 'primary');
  }

  &--list-type-check {
    &:before {
      content: '';
      left: -1.1rem;
      width: 0.6rem;
      height: 1.1rem;
      background-size: contain;
      background-position: center;
      background-image: url('../../assets/svg/check.svg');
      background-repeat: no-repeat;
    }
  }

  &--line-height-small {
    line-height: 1;
  }

  &--line-height-large {
    line-height: 1.88;
  }

  strong {
    font-weight: 600;
  }
}
