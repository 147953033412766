@import '../../assets/styles/variables';
.error-message {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: map-get($colors, 'error');

  &:not(.error-message--no-margin) {
    margin-left: 0.2rem;
  }
}
.error-message__icon {
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
