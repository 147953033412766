@import '../../assets/styles/variables';
.responsive-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.responsive-container__content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 3rem);
  @include media-breakpoint-up(xs) {
    width: map-get($container-max-widths, 'xs');
  }
  @include media-breakpoint-up(sm) {
    width: map-get($container-max-widths, 'sm');
  }
  @include media-breakpoint-up(md) {
    width: map-get($container-max-widths, 'md');
  }
  @include media-breakpoint-up(lg) {
    width: 45rem;
  }
}
