@import '../../assets/styles/variables';

.pages {
  display: flex;
  flex: 1 0 0;
  transition: background-color 200ms ease-out;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.pages__header {
  flex: 0 0 3rem;
  display: flex;
  align-items: center;
}

.pages__container {
  position: relative;
  display: flex;
  flex: 1 0 100%;
  overflow: hidden;
  width: 100%;
}

.pages__page {
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  padding-top: 1.5rem;

  &--fade-trans-enter {
    opacity: 0;
  }

  &--fade-trans-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-out, transform 500ms ease-out;
    height: 100%;
  }

  &--fade-trans-exit {
    opacity: 1;
  }

  &--fade-trans-exit-active {
    opacity: 0;
    height: 100%;
  }
}
