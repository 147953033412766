@import '../../assets/styles/variables';

.typography {
  margin: 0;
  line-height: normal;

  > strong {
    font-weight: 600;
  }

  &--h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: map-get($colors, 'white');
  }

  &--h2 {
    font-size: 1rem;
    font-weight: 600;
    color: map-get($colors, 'white');
  }

  &--h3 {
    font-size: 0.9rem;
    font-weight: 400;
    color: map-get($colors, 'white');
  }

  &--h4 {
    font-size: 0.8rem;
    font-weight: 600;
    color: map-get($colors, 'white');
  }

  &--h5 {
    font-size: 0.6rem;
    font-weight: 600;
    color: map-get($colors, 'black');
  }

  &--body-large {
    font-size: 0.9rem;
    font-weight: 400;
    color: map-get($colors, 'black');
  }

  &--body {
    font-size: 0.8rem;
    font-weight: 400;
    color: map-get($colors, 'black');
  }

  &--body-small {
    font-size: 0.7rem;
    font-weight: 400;
    color: map-get($colors, 'black');
  }

  &--body-small-light {
    font-size: 0.7rem;
    font-weight: 300;
    color: map-get($colors, 'black');
  }

  &--variant-primary {
    color: map-get($colors, 'white');
  }

  &--variant-secondary {
    color: map-get($colors, 'black');
  }
  &--variant-inherit {
    color: inherit;
  }

  &--line-height-lg {
    line-height: 1.5;
  }

  &--line-height-sm {
    line-height: 1.25;
  }

  &--line-height-xs {
    line-height: 1;
  }

  &--margin-bottom-lg {
    margin-bottom: 1rem;
  }

  &--margin-bottom-sm {
    margin-bottom: 0.5rem;
  }

  &--margin-bottom-xs {
    margin-bottom: 0.25rem;
  }
  &--margin-top-lg {
    margin-top: 1rem;
  }

  &--margin-top-sm {
    margin-top: 0.5rem;
  }

  &--margin-top-xs {
    margin-top: 0.25rem;
  }
}
