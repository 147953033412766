.collapsible-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  flex: 1 0 0;
}
.collapsible-header__city {
  font-size: 2rem;
  line-height: 1;
}
.collapsible-header__city-label {
  font-size: 0.9rem;
  line-height: 1;
}
.collapsible-header__price {
  font-size: 2rem;
  line-height: 1;
  align-self: flex-end;
}
.collapsible-header__price-label {
  font-size: 0.9rem;
  line-height: 1;
  align-self: flex-end;
}

.collapsible-header__column {
  display: flex;
  flex-direction: column;
}

.collapsible-header__row {
  display: flex;
  align-items: baseline;
}

.collapsible-header__aiport {
  font-size: 0.75rem;
  line-height: 1;
  padding-left: 0.5rem;
}
