@import '../../assets/styles/variables';
.button {
  &--variant-icon {
    padding: 0.6rem;
    background-color: rgba(map-get($colors, 'primary'), 0.05);
    border-radius: 100%;
    transition: background-color 250ms ease-out;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;
    }

    &.button--small {
      padding: 0.3rem 0.3rem;
    }

    &.button--small-padding {
      padding: 0.2rem 0.2rem;
    }

    &.button--no-padding {
      padding: 0;
      background-color: transparent;
    }

    &:hover {
      &:not(.button--no-padding) {
        background-color: rgba(map-get($colors, 'primary'), 0.2);
      }
    }
  }
}

.button__icon-child {
  width: 0.9rem;
  height: 0.9rem;
  fill: map-get($colors, 'primary');
  transition: fill 250ms ease-out;

  .button.button--no-padding:hover & {
    fill: map-get($colors, 'black');
  }
}
