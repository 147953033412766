@import '../../assets/styles/variables';
$duration: 250ms;
.collapsible {
  display: flex;
  flex-direction: column;
  width: 100%;

  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

  transition: border-color 250ms ease-out, box-shadow 250ms ease-out;

  & + & {
    margin-top: 0.5rem;
  }

  &--open {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &--error {
    border: 1px solid rgba(map-get($colors, 'error'), 0.5);

    &.collapsible--open {
      border: 1px solid map-get($colors, 'error');
    }
  }
}

.collapsible__header {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  justify-content: center;
  cursor: pointer;
}

.collapsible__container {
  &--transition {
    &-enter {
      height: 0;
      &.collapsible__container--transition-enter-active {
        position: relative;
        height: 100px;
        transition: height $duration ease-in-out;
        overflow: hidden;
      }
    }
    &-exit {
      height: 100px;
      &.collapsible__container--transition-exit-active {
        position: relative;
        height: 0;
        transition: height $duration ease-in-out;
        overflow: hidden;
      }
    }
  }
}

.collapsible__container-content {
  padding: 0 1rem 1rem;
  overflow: hidden;
}

.collapsible__label {
  color: map-get($colors, 'primary');
  font-size: 0.9rem;
  user-select: none;
  font-weight: 400;
  flex: 1 1 auto;
  padding-right: 1rem;

  .collapsible--error & {
    color: map-get($colors, 'error');
  }
}

.collapsible__label-icon {
  .collapsible__label--icon-left & {
    margin-right: 0.5rem;
  }

  .collapsible__label--icon-right & {
    margin-left: 0.5rem;
  }
}

.collapsible__header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1.2rem;
  height: 1.2rem;
}

.collapsible__expand-icon {
  fill: map-get($colors, 'primary');
  transition: transform 250ms ease-out;

  .collapsible--open & {
    transform: rotate(-180deg);
  }
}
