@import '../../../../../assets/styles/variables';

.detail-route {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.detail-route__details {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.detail-route__flight-codes {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1.7rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.17;
  letter-spacing: 0.00735em;
}

.detail-route__icon {
  fill: map-get($colors, 'primary');
}

.detail-route__airports {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  border-bottom: 0.1rem solid rgba(45, 49, 66, 0.3);
  padding-bottom: 0.5rem;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.detail-route__flight-carrier {
  display: grid;
  grid-template-columns: 1rem 1fr auto;
  grid-column-gap: 0.2rem;
  width: 100%;
  justify-content: flex-start;
  padding-top: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.detail-route__flight-carrier-logo {
  width: 1rem;
}

.detail-route__flight-times {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1rem;
  width: 100%;
  justify-content: space-between;
  padding-top: 0.5rem;
  align-items: end;
  height: 2.7rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.detail-route__flight-duration {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  align-self: start;
  letter-spacing: 0.03333em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #737373;
  flex: 1 1 0;
}

/* root: {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
},
icon: { flex: `0 0 ${theme.spacing(2)}px`, fill: teal.A700 },
airports: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  borderBottom: '1.5px solid rgba(45,49,66,0.3)',
  paddingBottom: theme.spacing(1)
},
airport: { flex: '1 0 0%', display: 'flex', flexDirection: 'column' },
airportNameLeft: { paddingRight: theme.spacing(2) },
airportNameRight: { paddingLeft: theme.spacing(2) },
airportRight: {
  alignItems: 'flex-end'
},
flightCarrier: {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  paddingTop: theme.spacing(1),
  alignItems: 'center'
},
flightCarrierLogo: {
  flex: `0 0 ${theme.spacing(2)}px`,
  height: theme.spacing(2)
},
flightCarrierName: {
  flex: `1 0 0%`,
  paddingLeft: theme.spacing(1)
},
flightStopovers: {},
flightTimes: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  alignItems: 'flex-start'
},
flightCodes: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
},
details: {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between'
},
time: {},
duration: {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
  borderBottom: '1px solid #737373',
  flex: 1,
  margin: `0 ${theme.spacing(2)}px`
} */
