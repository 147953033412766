@import '../../assets/styles/variables';
@import '../../assets/styles/normalize';
.app {
  position: relative;
  font-family: $body-font-family;
  transition: background-color 300ms ease-out;
  background-color: map-get($colors, 'white');
  flex: 1 0 100%;
  display: flex;
}
