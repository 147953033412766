@import '../../assets/styles/variables';
.page {
  display: flex;
  flex: 1 0 0;
  transition: background-color 200ms ease-out;
  // align-items: center;
  position: relative;
  flex-direction: column;
  flex: 1 0 100%;
}
