@import '../../assets/styles/variables';
.button {
  &--variant-primary {
    color: map-get($colors, 'white');
    background-color: map-get($colors, 'primary');
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.24);
    padding: 0.65rem 1.7rem;
    transition: background-color 250ms ease-out;

    &:hover {
      background-color: lighten(map-get($colors, 'primary'), 10%);
    }

    &:focus {
      background-color: darken(map-get($colors, 'primary'), 10%);
    }

    &.button--disabled {
      color: rgba(map-get($colors, 'white'), 0.2);
      background-color: rgba(map-get($colors, 'primary'), 0.2);
    }
  }
}
.button__icon {
  .button--variant-primary & {
    fill: map-get($colors, 'white');
  }

  .button--variant-primary.button--disabled & {
    fill: rgba(map-get($colors, 'white'), 0.2);
  }
}
