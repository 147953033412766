.flight-details {
  display: flex;
  flex-direction: column;
}

.flight-details__routes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  justify-content: space-between;
}

.flight-details__action {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
  width: 100%;
}

.flight-details__price {
  font-size: 2.125rem;
  font-weight: 400;
}

.flight-details__button {
  margin-left: 1rem;
}
.flight-details__button-icon {
  margin-left: 1rem;
}
