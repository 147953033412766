@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import './node_modules/bootstrap/scss/mixins';

$noto: 'Noto Sans', sans-serif;
$body-font-family: 'Source Sans Pro', sans-serif;

$colors: (
  'primary': #059ce2,
  'secondary': #f19000,
  'white': #fff,
  'black': #000,
  'dark': #00527c,
  'medium': #0282c9,
  'error': #f00
);

$grid-breakpoints: (
  // Extra small screen / phone
    xxs: 0,
  xs: 440px,
  // Small screen / phone
    sm: 576px,
  // Medium screen / tablet
    md: 768px,
  // Large screen / desktop
    lg: 992px,
  // Extra large screen / wide desktop
    xl: 1200px
);

$container-max-widths: (
  xs: 420px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
