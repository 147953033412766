@import '../../assets/styles/variables';
.ac-select-box-native {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.ac-select-box-native__value {
  padding: 1rem 1.4rem;
  border-radius: 0.6rem;
  line-height: 1;
  font-size: 1.8rem;
  background-color: map-get($colors, 'white');
  color: map-get($colors, 'black');
  .ac-select-box-native--transparent & {
    background-color: transparent;
    color: map-get($colors, 'white');
  }
  width: 100%;
  .ac-select-box-native--has-selection & {
    color: map-get($colors, 'dark');
  }
  .ac-select-box-native--focused & {
    box-shadow: rgba(map-get($colors, 'primary'), 0.5) 0px 0px 2px 2px;
  }
  .ac-select-box-native--has-selection.ac-select-box-native--transparent & {
    color: map-get($colors, 'white');
  }
  .ac-select-box-native--disabled.ac-select-box-native--transparent & {
    color: rgba(map-get($colors, 'white'), 0.5);
  }
  &:focus {
    outline: 0;
    box-shadow: rgba(map-get($colors, 'primary'), 0.5) 0px 0px 2px 2px;
    .ac-select-box-native--transparent & {
      box-shadow: none;
      background-color: rgba(map-get($colors, 'white'), 0.3);
    }
  }
}
.ac-select-box-native__select {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
