@import './variables';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700|Source+Sans+Pro:300,400,600&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-family: $body-font-family; // 2
  line-height: 1.15; // 3
  font-size-adjust: 100%; // 4
  -ms-overflow-style: scrollbar; // 5
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6
  // font-size: 1rem;
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
  @-ms-viewport {
    /* stylelint-disable-line at-rule-no-vendor-prefix */
    width: device-width;
  }
}

body {
  display: flex;
  margin: 0;
  font-family: $body-font-family; // 2
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; // based on a screen that is 1024px wide, 1.5625vw equals 16px
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  text-decoration: none;
  color: #000;
  flex-direction: column; // overflow-x: hidden;
}
