@import '../../assets/styles/variables';

.date-range {
  display: inline-flex;
  flex-direction: column;
  min-width: 11rem;

  &--full-width {
    display: inline-flex;
    flex: 1 0 0;
  }
}
.date-range__menu {
  z-index: 100000;
}

.date-range__button {
  font-family: $noto;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: normal;
  color: map-get($colors, 'black');
  transition: background-color 250ms ease-out, border-color 250ms ease-out;
  border: 1px solid rgba(map-get($colors, 'primary'), 0.5);
  outline: 0px solid transparent;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  line-height: 1;
  height: 2.4rem;

  .date-range--disabled & {
    cursor: not-allowed;
    pointer-events: none;
    color: map-get($colors, 'black');
    background-color: rgba(map-get($colors, 'black'), 0.5);
  }
  .date-range--error & {
    background-color: rgba(map-get($colors, 'error'), 0.5);
    &:focus {
      outline: 2px solid map-get($colors, 'error');
    }
  }
}

.date-range__button:-webkit-autofill,
.date-range__button:-webkit-autofill:hover,
.date-range__button:-webkit-autofill:focus {
  font: inherit;
  font-family: $noto;
  font-size: 0.9rem;
  font-weight: 400;
  -webkit-text-fill-color: map-get($colors, 'black');
}

.date-range__placeholder {
  position: absolute;
  pointer-events: none;
  padding: 0.7rem 0.8rem 0.75rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: map-get($colors, 'black');
  transition: opacity 150ms ease-out;

  .date-range--focused & {
    opacity: 0;
  }

  .date-range--disabled & {
    color: map-get($colors, 'black');
  }

  .date-range--error & {
    color: map-get($colors, 'error');
  }
  .date-range--error.date-range--disabled & {
    color: map-get($colors, 'error');
  }
}

.date-range__helper-text {
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  line-height: 1.3;
  color: map-get($colors, 'primary');
  margin-left: 0.8rem;
}
