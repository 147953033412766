@import '../../assets/styles/variables';

.icon {
  &--margin-left {
    margin-left: 0.5rem;
    .icon--small-margin & {
      margin-left: 0.25rem;
    }
  }
  &--margin-right {
    margin-right: 0.5rem;
    .icon--small-margin & {
      margin-right: 0.25rem;
    }
  }

  &--variant-primary {
    fill: map-get($colors, 'black');
  }

  &--variant-secondary {
    fill: map-get($colors, 'primary');
  }

  &--variant-error {
    fill: map-get($colors, 'error');
  }
}
