.flight-list-item {
  width: calc(100% - 4rem);
  transition: width 250ms ease-out;
  &--open {
    width: calc(100% - 2rem);
  }
}

.flight-list-item__summary-left {
  flex: 1 1 auto;
  padding-right: 0.5rem;
}

.flight-list-item__summary-right {
  flex: 1 1 auto;
  padding-left: 0.5rem;
  align-items: flex-end;
  text-align: right;
}

.flight-list-item__head-sub {
  font-size: 0.75rem;
  line-height: 1;
}
.flight-list-item__head-row {
  display: flex;
  align-items: baseline;
}
.flight-list-item__head-title {
  font-size: 2rem;
  line-height: 1;
}
.flight-list-item__head-nights {
  font-size: 0.75rem;
  line-height: 1;
  padding-left: 0.5rem;
}
.flight-list-item__panel-details {
  display: flex;
  flex-direction: column;
}
.flight-list-item__detail {
  & + & {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #000;
  }
}
